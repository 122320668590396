import { useEffect, useState } from 'react'
import { useFetch } from 'use-http'

const usePing = () => {
  const [needsPing, setNeedsPing] = useState(true)
  const { get /*, post, response, loading, error*/ } = useFetch(
    process.env.REACT_APP_HEROKU_URL
  )

  useEffect(() => {
    async function ping() {
      const testPing = await get('/')
    }

    if (needsPing) {
      ping().then((result) => {
        console.log('got here')
        setNeedsPing(false)
        setTimeout(
          () => {
            setNeedsPing(true)
          },
          60 * 1000 * 10
        )
      })
    }
  }, [get, needsPing])
}

export default usePing
