import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import CryptoJS from 'crypto-js'
import Context from './models/Context'

function isFromFollowUpBoss(context: string, signature: string) {
  const hmac = CryptoJS.HmacSHA256(
    context,
    process.env.REACT_APP_FUB_SECRET_KEY || ''
  )
  const hmacHex = hmac.toString(CryptoJS.enc.Hex)
  return hmacHex === signature
}

const useFubState = () => {
  const [context, _setContext] = useQueryParam('context', StringParam)
  const [contextObj, setContextObj] = useState<Context | undefined>(undefined)
  const [signature, _setSignature] = useQueryParam('signature', StringParam)
  const [fubVerified, setFubVerified] = useState(false)

  useEffect(() => {
    if (context && signature) {
      setFubVerified(isFromFollowUpBoss(context, signature))
    }
  }, [context, signature])

  useEffect(() => {
    if (context) {
      const decodedContext = JSON.parse(atob(context))
      setContextObj(decodedContext)
    }
  }, [context])

  return { contextObj, fubVerified }
}

export default useFubState
