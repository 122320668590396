import React from 'react'
import './App.css'
// @ts-ignore
import Retool from 'react-retool'
import useFubState from './useFubState'
import { useElementSize } from 'usehooks-ts'
import usePing from './usePing'

function App() {
  //const sample = { name: 'Sample data' }

  const [ref, { height, width }] = useElementSize()

  const { contextObj, fubVerified } = useFubState()
  usePing()
  console.log('contextObj', contextObj)
  console.log('fubVerified', fubVerified)

  return (
    <>
      <div ref={ref} style={{ height: '560px', position: 'relative' }}>
        <Retool
          url={process.env.REACT_APP_RETOOL_URL}
          sandbox
          //height="100vh"
          width={width}
          data={contextObj}
        />
        {/*fubVerified && (
        <div style={{ position: 'absolute', top: 16, right: 16 }}>From Fub</div>
      )*/}
        {process.env.REACT_APP_WHITELABEL === 'true' ? (
          <div
            style={{
              zIndex: 1000000,
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: 70,
              width: 95,
              background: 'white',
            }}
          />
        ) : null}
      </div>
    </>
  )
}

export default App
